<template lang="pug">
div 
    form(ref="formContato") 
        .p-grid.p-fluid.p-align-end
            .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.cd_banco.$error }")
                ProgressBar(v-if="waitingBanco" mode="indeterminate")
                div(v-else)
                    label.form-label Banco *:
                    Dropdown(v-model='model.cd_banco' :options='options.bancos'
                        optionLabel='nm_banco'  placeholder='Selecione' filter)
                    .feedback--errors(v-if='submitted && $v.model.cd_banco.$error')
                        .form-message--error(v-if="!$v.model.cd_banco.required") <b>Banco</b> é obrigatório.
                
            .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nr_agencia.$error }")
                label.form-label Número Agência *:
                InputText(type='text' v-model='$v.model.nr_agencia.$model')
            .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nr_conta.$error }")
                label.form-label Número Conta:
                InputText(v-model='$v.model.nr_conta.$model')

            .p-col-12.p-md-1
                label.form-label Operação:
                InputText(v-model='$v.model.nr_operacao.$model')
                
            .p-col-12.p-md-2
                ProgressBar(v-if="waitingTipoPix" mode="indeterminate")
                div(v-else)
                    label.form-label Tipo PIX:
                    Dropdown(v-model='model.ds_tipo_pix' :options='options.tipos_pix'
                        optionLabel='text' value="valor"  placeholder='Selecione')

            .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.ds_chave_pix.$error }")
                label.form-label Chave PIX:
                InputText(
                    v-model='model.ds_chave_pix'
                )
                .feedback--errors(v-if='submitted && $v.model.ds_chave_pix.$error')
                    .form-message--error(v-if="!$v.model.ds_chave_pix.required") <b>Chave PIX</b> deve ter o formato correto.

            .p-col-12.p-md-1
                Button(
                    @click="add(model)"
                    type="button"
                    icon="jam jam-plus"
                    style='max-width:160px; margin-right:5px'
                    v-tooltip="'Adicionar item'"
                )

    ProgressBar(v-if="waiting" mode="indeterminate")
    
    .form(v-for="(item, index) in list" :key="item.id" @change="update(item)" @keyup.enter="update(item)")
        ProgressBar(v-if="item.waiting" mode="indeterminate")
        div.banco-list(v-else)
            .p-grid.p-fluid.p-align.end 
                .p-col-12.p-md-2
                    ProgressBar(v-if="waitingBanco" mode="indeterminate")
                    div(v-else)
                        label.form-label Banco *:
                        Dropdown(v-model='item.cd_banco' :options='options.bancos'
                            optionLabel='nm_banco' placeholder='Selecione' filter)
                .p-col-12.p-md-2
                    label.form-label Número Agência *:
                    InputText(type='text' v-model='item.nr_agencia')
                .p-col-12.p-md-2
                    label.form-label Número Conta:
                    InputText(v-model='item.nr_conta')

                .p-col-12.p-md-1
                    label.form-label Operação:
                    InputText(v-model='item.nr_operacao')
                    
                .p-col-12.p-md-2
                    ProgressBar(v-if="waitingTipoPix" mode="indeterminate")
                    div(v-else)
                        label.form-label Tipo PIX:
                        Dropdown(v-model='item.ds_tipo_pix' :options='options.tipos_pix'
                            optionLabel='text' value="valor"  placeholder='Selecione')
                .p-col-12.p-md-2
                    label.form-label Chave PIX:
                    InputText(v-model='item.ds_chave_pix')
                .p-col-12.p-md-1
                    Button(
                        type="button"
                        icon="jam jam-trash"
                        class="p-button-danger"
                        style='max-width:160px; margin-top: 20px'
                        v-tooltip="'Remover item'"
                        @click="remover(index, item)"
                    )
</template>
    
<style></style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import { required, minLength, maxLength, email, numeric  } from 'vuelidate/lib/validators'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dropdown from 'primevue/dropdown'

    import { Bancos, DominioValor, PessoaJuridica } from '../../../middleware'

    const _ = require('lodash')

    export default {
        components: {
            ProgressBar, InputText, InputMask, Button, Tooltip, Dropdown
        },
        directives: {
            tooltip: Tooltip
        },
        props: ['parentData', 'pessoaJuridicaId'],
        created() {
            if (this.parentData) {
                this.parentData.map(item => { item.waiting = false; return item})
            }
            this.getBancos()
            this.getTiposPix()
        },
        data () {
            return {
                submitted: false,
                waitingBanco: false,
                waitingTipoPix: false,
                waiting: false,
                model: {
                    cd_banco: null,
                    nr_agencia: '',
                    nr_conta: '',
                    nr_operacao: '',
                    ds_tipo_pix: '',
                    cd_pessoa_juridica: null
                },
                list: this.parentData,
                options: {
                    bancos: [],
                    tipos_pix: []
                }
            }
        },
        validations () {
            const formatValidate = (value) => {
                const t = this.model.ds_tipo_pix
                if (t == 'Email') return email(value)
                if (t) return required(value)
                return true
            }

            let v = {
                model: {
                    cd_banco: { required},
                    nr_agencia: { required,  numeric, minLength: minLength(2), maxLength: maxLength(5) },
                    nr_conta: { required, numeric, minLength: minLength(2) },
                    nr_operacao: { minLength: minLength(1), numeric },
                    ds_chave_pix: { minLength: minLength(5), formatValidate }
                }
            }

            return v
        },
        methods: {
            getTiposPix() {
                this.waitingTipoPix = true
                DominioValor.findAll({'ds_mnemonico': 'TIPO_PIX'}).then(response => {
                    if (response.status === 200) {
                        this.options.tipos_pix = response.data['TIPO_PIX'].valores.map(valor => ({
                            text: valor.ds_valor,
                            value: valor.ie_valor
                        }))

                        this.list = this.list.map(item => {
                            this.options.tipos_pix.some(option => {
                                const flag = option.value === item.ds_tipo_pix
                                if (flag) {
                                    item.ds_tipo_pix = option
                                }
                                return flag
                            })
                            return item
                        })
                    }
                    this.waitingTipoPix = false
                })
            },
            getBancos() {
                this.waitingBanco = true
                Bancos.findAll().then(response => {
                    if (response.status === 200) {
                        this.options.bancos = response.data
                        this.options.bancos.forEach(option => {
                            this.list.forEach(banco => {
                                if (banco.cd_banco == option.id) {
                                    banco.cd_banco = option
                                }
                            })
                        })
                    }
                    this.waitingBanco = false
                })
            },
            add(banco) {
                this.submitted = true
                this.$v.$touch()
                if (this.$v.$invalid) return 0
                let novo_banco = _.cloneDeep(banco)
                for (let key in banco) {
                    banco[key] = ""
                }

                if (this.pessoaJuridicaId) {
                    this.waiting = true
                    const data = {
                        cd_banco: novo_banco.cd_banco.id,
                        nr_agencia: novo_banco.nr_agencia,
                        nr_conta: novo_banco.nr_conta,
                        nr_operacao: novo_banco.nr_operacao,
                        ds_tipo_pix: novo_banco.ds_tipo_pix.value,
                        ds_chave_pix: novo_banco.ds_chave_pix,
                        cd_pessoa_juridica: this.pessoaJuridicaId
                    }   
                    return PessoaJuridica.saveBanco(data).then((response) => {
                        if (response.status == 201) {
                            novo_banco.id = response.data.id
                            this.list.push(novo_banco)
                            this.$toast.success('Dado bancário salvo com sucesso', { duration: 3000 })
                        } else {

                                this.$toast.error(response.data.details, { duration: 3000 })

                        }
                        this.waiting = false
                    })
                } else {
                    this.list.push(novo_banco)
                }
                this.emitir()
            },
            remover(index, item) {
                if (item.id) {
                    item.waiting = true
                    this.$forceUpdate()
                    return PessoaJuridica.removeBanco(item.id).then(response => {
                        if (response.status === 204) {
                            this.list.splice(index, 1)
                            this.$toast.success('Dado bancário removido com sucesso', { duration: 3000 })
                        } else {

                                this.$toast.error(response.data.details, { duration: 3000 })

                        }
                        item.waiting = false
                        this.$forceUpdate()
                    })
                } else {
                    this.list.splice(index, 1)
                }
                this.emitir()
            },
            update(item) {
                this.submitted = true
                if (item.id) {
                    item.waiting = true
                    this.$forceUpdate()
                    const data = {
                        id: item.id,
                        nr_agencia: item.nr_agencia,
                        nr_conta: item.nr_conta,
                        nr_operacao: item.nr_operacao,
                        ds_tipo_pix: item.ds_tipo_pix?.value,
                        ds_chave_pix: item.ds_chave_pix,
                        cd_pessoa_juridica: this.pessoaJuridicaId
                    } 
                    return PessoaJuridica.saveBanco(data).then(response => {
                        if (response.status === 200) {
                            this.$toast.success('Dados bancários salvo com sucesso', { duration: 3000 })
                        } else {

                            this.$toast.error(response.data.details, { duration: 3000 })

                        }
                        item.waiting = false
                        this.$forceUpdate()
                    })
                }
                this.emitir()
            },
            emitir() {
                let bancos = _.cloneDeep(this.list)
                this.$emit('bancosEmitido', bancos)
            },
        }
    }
</script>