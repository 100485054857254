<template lang="pug">
div 
    form(ref="formContato")
        .p-grid.p-fluid.p-align-end
            .p-col-12.p-md-3
                label.form-label Contato *:
                InputText(type='text' v-model='$v.model.nm_contato.$model')
            .p-col-12.p-md-2
                label.form-label Telefone:
                InputMask(:mask="'(99) 99999999?9'" v-model='$v.model.nr_telefone.$model')

            .p-col-12.p-md-2
                label.form-label Celular:
                InputMask(:mask="'(99) 99999999?9'" v-model='$v.model.nr_celular.$model')
                
            .p-col-12.p-md-2
                label.form-label Whatsapp:
                InputMask(:mask="'(99) 99999999?9'" v-model='$v.model.nr_whatsapp.$model')
                    

            .p-col-12.p-md-2
                label.form-label Email:
                InputText(type='text' v-model='$v.model.ds_email.$model')
                    

            .p-col-12.p-md-1
                Button(
                    @click="add(model)"
                    type="button"
                    icon="jam jam-plus"
                    style='max-width:160px; margin-right:5px'
                    v-tooltip="'Adicionar item'"
                )

    ProgressBar(v-if="waiting" mode="indeterminate")
    .form(v-for="(item, index) in list" @change="update(item)" @keyup.enter="update(item)" :key="item.id")
        ProgressBar(v-if="item.waiting" mode="indeterminate")
        div.contato-list(v-else)
            .p-grid.p-fluid.p-align.end
                .p-col-12.p-md-3
                    label.form-label Contato *:
                    InputText(type='text' v-model='item.nm_contato')

                .p-col-12.p-md-2    
                    label.form-label Telefone:
                    InputMask(:mask="'(99) 99999999?9'" v-model='item.nr_telefone')
                
                .p-col-12.p-md-2
                    label.form-label Celular:
                    InputMask(:mask="'(99) 99999999?9'" v-model='item.nr_celular')
                    
                .p-col-12.p-md-2
                    label.form-label Whatsapp:
                    InputMask(:mask="'(99) 99999999?9'" v-model='item.nr_whatsapp')
                        
                .p-col-12.p-md-2
                    label.form-label Email:
                    InputText(type='text' v-model='item.ds_email')

                .p-col-12.p-md-1
                    Button(
                        type="button"
                        icon="jam jam-trash"
                        class="p-button-danger"
                        style='max-width:160px; margin-top: 20px'
                        v-tooltip="'Remover item'"
                        @click="remover(index, item)"
                    )
</template>

<style>
.contato-list {
    display: flex;
    flex-direction: column-reverse;
}
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import { required, minLength } from 'vuelidate/lib/validators'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'

    import { PessoaJuridica } from '../../../middleware'

    const _ = require('lodash')

    export default {
        components: {
            ProgressBar, InputText, InputMask, Button, Tooltip
        },
        directives: {
            tooltip: Tooltip
        },
        props: ['parentData', 'pessoaJuridicaId'],
        created () {
            if (this.parentData) {
                this.parentData.map(item => { item.waiting = false; return item})
            }
        },
        data () {
            return {
                waiting: false,
                model: {
                    nm_contato: '',
                    nr_telefone: '',
                    nr_celular: '',
                    nr_whatsapp: '',
                    ds_email: ''
                },
                list: this.parentData,
            }
        },
        validations () {
            let v = {
                model: {
                    nm_contato: { required, minLength: minLength(2) },
                    nr_telefone: { required, minLength: minLength(8) },
                    nr_celular: { minLength: minLength(8) },
                    nr_whatsapp: { minLength: minLength(8) },
                    ds_email: { minLength: minLength(2) },
                }
            }

            return v
        },
        methods: {
            handleError(err) {
                if(typeof err === 'string') {
                    return err
                } else if (Array.isArray(err)) {
                    return err.join(', ')
                } else if (typeof err === 'object' && err != null) {
                    let messages = []
                    for (let key in err) {
                        if(typeof err === 'string') {
                            messages.push(`${key}: ${err[key]}`)
                        }
                        else if (Array.isArray(err)) {
                            messages.push(`${key}: ${err[key].join(',')}`)
                        }
                        else {
                            messages.push(`${key}: Erro inesperado`)
                        }
                    }
                    return messages.join(', ')
                } else {
                    return 'Erro inesperado'
                }
            },
            add(contato) {
                contato.nr_telefone = contato.nr_telefone.replace(/\D/g, "")
                contato.nr_celular = contato.nr_celular.replace(/\D/g, "")
                contato.nr_whatsapp = contato.nr_whatsapp.replace(/\D/g, "")
                contato.waiting = false
                const novo_contato = _.cloneDeep(contato)

                for (let key in contato) {
                    contato[key] = ""
                }

                if (this.pessoaJuridicaId) {
                    this.waiting = true
                    const data = {
                        nm_contato: novo_contato.nm_contato,
                        nr_telefone: novo_contato.nr_telefone,
                        nr_celular: novo_contato.nr_celular,
                        nr_whatsapp: novo_contato.nr_whatsapp,
                        ds_email: novo_contato.ds_email,
                        cd_pessoa_juridica: this.pessoaJuridicaId
                    }   
                    return PessoaJuridica.saveContato(data).then((response) => {
                        if ([200, 201].includes(response.status)) {
                            this.$toast.success('Contato salvo com sucesso', { duration: 3000 })
                            novo_contato.id = response.data.id
                            this.list.push(novo_contato)
                        } else {
                            this.$toast.error(this.handleError(response.data), { duration: 3000 })
                        }
                        this.waiting = false
                    })
                } else {
                    this.list.push(novo_contato)
                }
                this.emitir()
            },
            remover(index, item) {
                if (item.id) {
                    item.waiting = true
                    this.$forceUpdate()
                    return PessoaJuridica.removeContato(item.id).then(response => {
                        if ([200, 204].includes(response.status)) {
                            this.$toast.success('Contato removido com sucesso', { duration: 3000 })
                            this.list.splice(index, 1)
                        } else {
                            this.$toast.error(this.handleError(response.data.detail), { duration: 3000 })
                        }
                        item.waiting = false
                        this.$forceUpdate()
                    })
                } else {
                    this.list.splice(index, 1)
                }
                this.emitir()
            },
            update(item) {
                if (item.id) {
                    item.waiting = true
                    this.$forceUpdate()
                    const data = {
                        id: item.id,
                        nm_contato: item.nm_contato,
                        nr_telefone: item.nr_telefone,
                        nr_celular: item.nr_celular,
                        nr_whatsapp: item.nr_whatsapp,
                        ds_email: item.ds_email,
                        cd_pessoa_juridica: this.pessoaJuridicaId
                    } 
                    return PessoaJuridica.saveContato(data).then(response => {
                        if ([200, 201].includes(response.status)) {
                            this.$toast.success('Contato salvo com sucesso', { duration: 3000 })
                        } else {
                            this.$toast.error(this.handleError(response.data.detail), { duration: 3000 })
                        }
                        item.waiting = false
                        this.$forceUpdate()
                    })
                }
                this.emitir()
            },
            emitir() {
                this.$emit('contatosEmitido', this.list)
            },
        }
    }
</script>